<template>
  <div class="terceros">
    <TablaFranquicias></TablaFranquicias>
  </div>
</template>

<script>
export default {
  components: {
    TablaFranquicias: () =>
      import('@/components/terceros/TablaFranquicias.vue'),
  },
};
</script>

<style></style>
